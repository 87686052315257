.navigator-bar {
    background-color: #333333;
}

.navigator-content {
    display: flex;
    vertical-align: center;
}

.link {
    align-items: center;
    background-color: transparent;
    border: none;
    border-radius: 4px;
    color: #999999;
    display: inline-flex;
    font-size: 16px;
    font-weight: 700;
    justify-content: center;
    margin: 4px;
    padding: 4px 4px 4px 4px;
    text-decoration: none;
}

.link-alt {
    align-items: center;
    background-color: transparent;
    border: none;
    border-radius: 4px;
    color: rgba(0,0,0,0.65);
    display: inline-flex;
    font-size: 16px;
    font-weight: 500;
    justify-content: center;
    margin: 4px;
    padding: 4px 4px 4px 4px;
    text-decoration: none;
}

.link-alt:hover {
    align-items: center;
    background-color: transparent;
    border: none;
    border-radius: 4px;
    color: rgba(0,0,0,0.80);
    display: inline-flex;
    font-size: 16px;
    font-weight: 500;
    justify-content: center;
    margin: 4px;
    padding: 4px 4px 4px 4px;
    text-decoration: none;
}