.App {
  /*text-align: center;*/
  background-color: #666666;
}

.App-logo {
  height: 40px;
  pointer-events: none;
}

.App-header {
  background-color: #666666;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #D4A373;
}

.App-Title {
  color: #fffffe;
}

.pfs-image {
  align-self: center;
  height: 64px;
  padding: 8px;
  width: 64px;
}

.pfs-container {
  padding: 16px;
}

.discord {
  align-self: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 8px;
  width: 150px;
}