.table-header {
    background-color: #111111;
    color: #FFFFFF;
}

.table-row {
    background-color: #111111;
    color: #FFFFFF;
}

.table-row:hover {
    background-color: #ffffff;
    color: #111111;
}

.table-score-col {
    align-self: end;
    text-align: right;
}

.table-name-col {
    align-self: start;
    text-align: left;
}
